.profile-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;

  &-card {
    padding: 16px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    flex: 1 0 auto;
    width: 100%;
  }

  &-buttons {
    padding-top: 32px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
