@import "../../styles/mixins.scss";

.ui-form-date-picker {
  .ant-row {
    display: contents;
  }
  .ant-form-item-label {
    display: flex;
    label {
      @include label;
    }
  }
}
