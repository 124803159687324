.comment-container {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: inline-flex;
  }

  & div:last-child {
    margin-left: 4px;
  }

  &-textarea {
    height: 100px;
    width: 90%;
  }
}
