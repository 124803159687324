.claim-container {
  position: relative;
  height: 100%;
  &-info {
    box-sizing: border-box;
    padding: 16px 8px;
    border-bottom: 1px solid rgba(166, 172, 185, 0.3);
    margin-bottom: 20px;
  }

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
