@import "../../components/UI/styles/palette.scss";

.candidates-table {
  &-name {
    padding: 0 16px !important;
    width: 230px;
    max-width: 230px;
    min-width: 230px;
  }

  &-address {
    padding: 0 16px !important;
    min-width: 257px;
    max-width: 257px;
    width: 257px;
  }

  &-status {
    min-width: 180px;
    max-width: 180px;
    width: 180px;
    padding: 0 0 0 16px !important;
  }

  &-positions {
    padding: 0 16px !important;
    min-width: 288px;
    max-width: 288px;
    width: 288px;
  }

  &-row {
    &--highlighted {
      color: rgba(166, 172, 185, 1);
    }

    &--didnt-work {
      background-color: $blue-color-7;
    }
  }
}
