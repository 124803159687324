.cold-search {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;

  & > table {
    margin-bottom: 48px;
  }
}
