.candidates-actions {
  position: relative;
  height: 100%;
  width: 100%;

  &-btns {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    & button {
      margin-right: 10px;
    }
  }
  &-btn {
    margin-top: 16px;
    width: 100%;
  }
  &-phone {
    width: 200px;
  }

  &-updater span {
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      color: #0057ac;
    }
  }
}
