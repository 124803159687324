.sidePanel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 320px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;

    &-name {
      font-size: 16px;
      font-weight: bold;
    }

    &-role {
      color: #a6acb9;
      font-size: 14px;
    }

    &-close-icon {
      cursor: pointer;
      margin-bottom: 20px;
    }
  }

  &-separator {
    width: calc(100% - 32px);
    margin: 0 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  &-menu {
    height: 100%;
    border-right: none;
  } 

  &-content {
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &-menu-item {
    display: flex;
    gap: 5px;
    align-items: center;

    &:hover {
      color: #000000;
    }
  }

  .sidePanel-menu-item__logout {
    color: #e62249 !important;
    position: absolute;
    bottom: 30px;
  }
}
