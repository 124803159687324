.candidate-container {
  position: relative;
  height: 100%;
  padding-top: 24px;

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
