@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-text-area {
  &.ant-input {
    @include border($border-color);
    border-radius: 8px !important;
  }

  &:focus {
    @include focus;
  }

  &--disabled {
    @include disabled;
  }

  &--required::after {
    @include error;
  }
}
