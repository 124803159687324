.ui-radio {
  .ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type="radio"] {
    width: 16px;
    height: 16px;
  }
  .ant-radio-input {
    opacity: 1;
    &:focus {
      outline: none !important;
    }
  }
  [type="checkbox"]:focus,
  [type="radio"]:focus {
    outline: none !important;
    box-shadow: none !important;
    --tw-ring-inset: initial !important;
    --tw-ring-offset-width: 0 !important;
    --tw-ring-offset-color: initial !important;
    --tw-ring-color: initial !important;
    --tw-ring-offset-shadow: initial !important;
    --tw-ring-shadow: initial !important;
  }

  .ant-radio-input[type="radio"] {
    border-radius: 2px !important;
    border: 1px solid rgba(166, 172, 185, 0.3) !important;
  }
  //.ant-radio-input[type='radio']:focus {
  //    outline: none!important;
  //}
  .ant-radio-input[type="radio"]:checked {
    border-radius: 2px !important;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4IiBmaWxsPSJub25lIj4KICAgIDxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8xMDdfOTEzMCkiPgogICAgICAgIDxyZWN0IHg9IjEuNSIgeT0iMS41IiB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHJ4PSIzLjMzMzMzIiBmaWxsPSIjMDA1N0FDIiBzdHJva2U9IiMwMDU3QUMiIHN0cm9rZS13aWR0aD0iMS4xNjY2NyIvPgogICAgICAgIDxwYXRoIGQ9Ik0xMyA2TDcuNCAxMkw1IDkuNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KICAgIDwvZz4KICAgIDxkZWZzPgogICAgICAgIDxjbGlwUGF0aCBpZD0iY2xpcDBfMTA3XzkxMzAiPgogICAgICAgICAgICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9IndoaXRlIi8+CiAgICAgICAgPC9jbGlwUGF0aD4KICAgIDwvZGVmcz4KPC9zdmc+Cg==") !important;
  }

  .ant-radio-checked::after {
    border: none !important;
  }
  .ant-radio-inner::after {
    border: none;
  }
  .ant-radio-inner {
    border: none;
  }
}
