.vacancy-container {
  padding: 0 16px 64px;
  position: relative;
  height: 100%;

  &-loading {
    margin-top: 24px;
  }

  &-table {
    margin-bottom: 12px;

    &-candidates {
      min-height: 450px;
    }

    &-spinner {
      justify-content: center;
      margin-top: 80px;
    }
  }

  &-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-copyTooltip {
    display: flex;
    align-items: center;
  }

  &-copy {
    cursor: pointer;
    margin-right: 8px;
  }

  &-link {
    cursor: pointer;
    color: #0057ac;
  }

  &-id {
    width: 68px;
    max-width: 68px;
  }

  &-duration {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    font-size: 13px;
  }

  &-address {
    width: 237px;
    max-width: 237px;
  }

  &-phone {
    width: 155px;
    max-width: 155px;
    min-width: 155px;

    &-call:hover {
      color: #0057ac;
      position: relative;
      text-decoration-line: underline;
    }
  }

  &-positions {
    width: 230px;
    max-width: 230px;
    min-width: 230px;
  }

  &-info {
    margin-bottom: 36px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background: #fff;
  }
}
