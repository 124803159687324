@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-select-dadata {
  outline: none;
  .react-dadata__input {
    border-radius: 8px;
    padding: 4px 11px;
    font-size: 14px;
    color: $black-color;
    @include border($border-color);
  }
  &--large .react-dadata__input {
    height: 40px;
  }

  &--middle .react-dadata__input {
    height: 32px;
  }

  &--small .react-dadata__input {
    height: 24px;
  }
  .react-dadata__input:hover {
    border-color: $border-hover-color;
    color: $hover-color;
  }
  .react-dadata__input:focus {
    @include focus;
  }
}
.ui-select-dadata--disabled .react-dadata__input {
  @include disabled;
  cursor: not-allowed;
  pointer-events: none;
}

.ui-select-dadata--error .react-dadata__input {
  border-color: $border-hover-color;
}

.react-dadata__suggestions {
  border-radius: 8px;
}
.react-dadata__suggestion {
  border-radius: 8px;
  margin: 3px auto;
  width: 98%;
}
