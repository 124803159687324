.report-table {
  position: relative;
  height: 100%;

  &-column {
    &-name {
      width: 135px;
      min-width: 135px;
    }

    &-address {
      width: 243px;
      min-width: 243px;
    }

    &-info {
      width: 103px;
      min-width: 103px;
    }
  }

  &-row {
    padding: 0 !important;
  }

  th {
    padding: 16px !important;
  }

  .report-table-row--with {
    min-width: 170px;
    max-width: 170px;
  }

  &-cell {
    padding: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
  }

  &-link-dropdown-trigger {
    cursor: pointer;
  }
}
