.ui-dropdown {
  &-overlay {
    & > ul {
      border-radius: 8px;
    }
    & li:hover {
      background-color: #dfebfd;
    }
  }
}
