@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-tree-select {
  &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
    border-radius: 8px;
    color: $black-color;
    font-size: 14px;
    @include border($border-color);
  }
  &.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):hover
    > .ant-select-selector {
    @include hover;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: $blue-color-9;
    box-shadow: none;
    color: $blue-color-9;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 40px;
  }
  .ant-select-arrow {
    color: $blue-color-9;
    @include square(16px);
  }

  &-popup {
    .ant-select-tree-node-selected {
      background-color: $blue-color-3 !important;
    }
  }
  &--error {
    .ant-select-selector {
      border-color: $border-error-color;
    }
  }

  input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: red;
  }
}
