@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-input {
  &.ant-input {
    border-radius: 8px;
    margin: 0;
    font-size: 14px;
    @include border($border-color);
  }
  &.ant-input-lg {
    padding: 8px 11px;
  }
  &.ant-input-affix-wrapper-lg {
    padding: 8px 11px;
  }
  &.ant-input-affix-wrapper {
    @include border($border-color);
    border-radius: 8px;
    font-size: 14px;
  }
  &.ant-input:hover {
    @include hover;
  }
  &.ant-input:focus {
    @include focus;
  }
  &.ant-input-affix-wrapper:focus {
    @include focus;
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    @include hover;
  }

  &.ant-input-disabled.ant-input[disabled] {
    @include disabled;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    height: 0;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }
}
.ui-input.ui-input--error.ant-input {
  border-color: $border-error-color;
}
.ui-input.ant-input.ui-input--required::after {
  @include error;
}
.ui-input.ui-input--error.ant-input-affix-wrapper {
  border-color: $border-error-color;
}
.ui-input.ant-input-affix-wrapper.ui-input--required::after {
  @include error;
}
