@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

.ui-form-input {
  .ant-row {
    display: contents;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):before {
    display: none;
  }
  .ant-form-item-label {
    display: flex;
    overflow: visible;
    label {
      @include label;
    }
    label.ant-form-item-required {
      flex-direction: row !important;
    }
  }
  input::placeholder {
    color: $placeholder-color !important;
    font-weight: 500 !important;
  }
}
