.field-avitoTags {
  display: flex;
  align-items: center;
  padding: 16px 0;

  &-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-content {
    padding: 16px 24px;
  }

  &-group {
    display: flex;
    align-items: center;

    button:first-child {
      margin-right: 16px;
    }
  }
}
