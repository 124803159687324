.custom-placemark {
  position: relative;
  text-align: center;

  .placemark-content {
    width: 100px;
    background: #0057ac;
    color: #fff;
    border-radius: 10px;
    padding: 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;

    &--mode {
      background: #e69e19;
    }
  }

  .placemark-svg {
    position: absolute;
    left: -50%;
    transform: translateX(40px);
    width: 13px;
    height: 9px;
    top: 100%;
  }
}

.custom-cluster-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #0057ac;
  background: #ffffff;
  color: #0057ac;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
}
