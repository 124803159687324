.recommended-container {
  padding: 0 16px 64px;
  position: relative;
  height: 100%;

  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  font-style: normal !important;

  &-vacansy-name {
    color: #0057ac;
    cursor: pointer;
  }

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-maps {
    border-radius: 12px;
    margin: 24px 0;
  }

  &-candidate {
    margin-top: 8px;
    overflow-x: auto;
  }

  &-pagination {
    margin-top: 16px;
  }
}
