.modal-content {
  background: #fff;
  border-radius: 16px;
  padding: 12px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-title {
      margin: 24px 0;
    }
  }
  &-body {
    color: #a6acb9;
    font-size: 14px;
    &-text {
      margin: 0;
      .highlight {
        color: #0057ac;
        text-decoration: underline;
      }
    }
  }
  &-close {
    margin-top: 20px;
  }
}
