.field-name {
  display: flex;
  align-items: center;
  padding: 16px 0;

  &-hover {
    color: #0057ac;
  }

  &-edit {
    padding: 8px 0;
  }
  &-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-address-suggest {
    position: relative;
    &-suggestions {
      height: 100px;
      overflow-y: scroll;
      bottom: calc(100% + 4px);
      top: auto;
    }
  }

  &-address {
    outline: none !important;
    height: 30px;
    width: 204px;
    border-radius: 8px;
    border: solid 1px #a6acb94d;
    padding: 4px 11px;
    font-size: 14px;
    color: #000;
    padding-right: 24px;

    &:focus {
      border: none !important;
    }
  }

  &-icon {
    display: inline-block;
    margin-left: auto;
    cursor: pointer;

    &--address {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4px;
    }
  }
}
