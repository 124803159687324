@import "../styles/palette.scss";
@import "../styles/mixins.scss";

$bg-color: $grey-color-2;
$bg-hover-color: $blue-color-1;
$border-color: $grey-color-3;
.ui-table-container {
  overflow-x: auto;
}
.ui-table {
  .ant-table {
    .ant-table-container {
      border-radius: 10px !important;
      font-size: 13px;
      font-weight: 500;
      .ant-table-thead > tr > th {
        background: $bg-color;
        color: $grey-color-7;
        border-bottom: 1px solid $border-color !important;
        font-weight: 600;

        @include nowrap;

        &:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan]):before {
          background-color: rgba(0, 0, 0, 0.06) !important;
        }

        &:not(:last-child) {
          border-right: 1px solid transparent !important;
        }
      }

      & table {
        border-top: 1px solid $border-color !important;
        border-radius: 10px 10px 0 0;
      }

      & thead > tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      & thead > tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }

      & tbody > tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }

      & tbody > tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }

      .ant-table-content tbody > tr th,
      .ant-table-content tbody > tr td {
        border-bottom: 1px solid $border-color !important;
        line-height: 18px;
        padding: 12px 16px;

        &:not(:last-child) {
          border-right: 1px solid transparent !important;
        }
      }

      tbody > tr:hover > td {
        background-color: $bg-hover-color;
      }
    }
  }
}
