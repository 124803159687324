.modal-call {
  &-toggle {
    font-size: 13px;
    color: gray;
    cursor: pointer;
    display: inline-block;
    margin-left: 16px;
  }
  &-collapse {
    bottom: auto !important;
    right: auto !important;
    box-shadow: 0px -1px 8px 5px rgba(0, 0, 0, 0.27);
    top: 40px !important;
    left: 40px !important;
  }
}
