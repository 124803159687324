@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-input-mask-wrapper {
  display: flex;
}

.ui-input-mask-suffix {
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}

.ui-input-mask {
  @include border($border-color);
  border-radius: 8px;
  margin: 0;
  padding: 4px 11px;
  width: 100%;
  &--large {
    height: 40px;
  }

  &--middle {
    height: 32px;
  }

  &--small {
    height: 24px;
  }

  &:hover {
    @include hover;
  }

  &--disabled {
    @include disabled;
    &:hover {
      border-color: $border-disabled-color;
      @include disabled;
    }
  }

  &--required::after {
    color: $error-color;
    border-color: $border-error-color;
  }

  &--error {
    border-color: $border-error-color;
  }

  &--number::-webkit-outer-spin-button,
  &--number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &--number[type="number"] {
    -moz-appearance: textfield;
  }
}

.ui-input-mask-wrapper .ui-input-mask:focus {
  @include focus;
}
