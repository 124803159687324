/* common */

.card-activity {
  background: #ffffff;
  padding: 0.75rem;
  margin-bottom: 1rem;
  box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
}

.separator {
  margin: 1rem -0.75rem;
  display: flex;
  justify-content: center;
}

.description {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.action-text {
  font-size: 0.875rem;
}

.action-text--bold {
  font-size: 0.875rem;
  font-weight: bold;
}

.text-gray {
  color: #6b7280;
}
.text-medium {
  font-size: 0.875rem;
}

.text-small {
  font-size: 0.75rem;
}

.activity-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* status */
.block-statuses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.refusal-reason,
.manager-comment {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.system-name {
  font-size: 0.875rem;
  color: #585d68;
  display: block;
  padding-bottom: 0.5rem;
}

/* contacts */
.contacts-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.address-wrapper,
.relevance-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cc-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contacts:not(:last-child) {
  padding-bottom: 0.5rem;
}
.contacts:last-child {
  padding-top: 0.5rem;
}

/* common fields */
.common-field {
  margin: 0.5rem 0;
}
.common-field:first-child {
  margin-top: 1rem;
}
