.dictionary {
  padding: 0 25px;

  &-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 50%;
    gap: 0 20px;

    &-item {
      margin-bottom: 8px;
    }
  }

  &-modal-actions {
    display: flex;
    align-items: center;
    gap: 0 16px;
  }
}
