.ui-collapse {
  border-radius: 8px;
  width: 100%;

  &-panel-header {
    border-radius: 0 0 8px 8px !important;
    font-size: 14px;

    .ant-collapse-content-box,
    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header {
      padding: 0 !important;
    }
  }
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 8px 8px !important;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 40px 8px 16px !important;
}
.ant-collapse-header {
  background-color: rgba(241, 242, 244, 0.6);
  border-radius: 8px 8px 0 0 !important;
}
