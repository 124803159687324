.reply-container {
  position: relative;
  height: 100%;
  &-info {
    padding: 0 16px 64px;
  }

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
