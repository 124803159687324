.ui-tag {
  &.ant-tag {
    font-weight: 600;
    line-height: 18px;
    font-size: 12px;
    border-radius: 37px;
    padding: 2px 8px;
    color: #000;
    border: none;
  }

  &.ui-tag--default {
    color: #a6acb9;
    background-color: #efefef;
  }
  &.ui-tag--green {
    color: #1eb22d;
    background-color: #c2ffc8;
  }
  &.ui-tag--orange {
    color: #c69900;
    background-color: #ffeba7;
  }
  &.ui-tag--red {
    color: #e62249;
    background-color: #ffd9e0;
  }
}
