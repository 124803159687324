.people-count {
  margin: 8px;
  display: flex;
  align-items: center;

  .column {
    flex: 1;
    text-align: center;
    padding: 10px;

    &:not(:last-child) {
      border-right: 2px solid #ccc;
    }
  }
}
