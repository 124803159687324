@import "../styles/palette.scss";
@import "../styles/mixins.scss";

@mixin buttonHover {
  color: $focus-color;
  background-color: $bg-hover-color;
  border-radius: 4px;
}

$width-btn: 38px;

.ui-editor {
  &-label {
    margin-bottom: 4px;
  }

  &-textarea {
    .ql-toolbar {
      @include border($border-color);
      border-radius: 8px 8px 0 0;
      background-color: $grey-color-2;
      padding: 3px 5px;
      @include flex-block(center, flex-start);

      .ql-formats {
        @include flex-block();
        position: relative;
        margin-right: 2px;

        &:not(:last-child)::after {
          @include fakeContent($border-color, -2px);
        }
      }

      &.ql-snow .ql-picker.ql-expanded {
        .ql-picker-options,
        .ql-picker-label {
          border-color: $border-color;
        }
      }

      &.ql-snow {
        .ql-stroke {
          stroke: $grey-color-7;
          &.ql-fill {
            fill: $grey-color-7;
          }
        }
        .ql-fill {
          fill: $grey-color-7;
        }
      }
    }

    .ql-container {
      @include border($border-color);
      border-radius: 0 0 8px 8px;
      font-size: 14px;
      color: $black-color-8;
    }

    button[type="button"] {
      background-color: transparent;
      transition: background-color 0.5s ease-in-out;
      @include flex-block();
      padding: 3px 11px;
      width: $width-btn;

      &:hover,
      &.ql-active {
        @include buttonHover;
      }

      svg {
        @include square(16px);
      }
    }

    .ql-icon-picker {
      width: $width-btn;
    }

    .ql-picker-label {
      background-color: transparent;
      transition: background-color 0.5s ease-in-out;
      @include flex-block();
      padding: 3px 11px;
      width: $width-btn;
      &:hover {
        @include buttonHover;
      }
      svg {
        @include square(16px);
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-character {
    @include flex-block(flex-start, space-between);
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $grey-color-7;
    margin-top: 2px;

    &-text {
      min-width: 80%;
      margin: 0;
    }

    &-error {
      @include error;
    }

    &-count {
      @include flex-block(center, flex-end);
    }
  }
}
