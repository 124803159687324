.candidate-reply {
  width: 100%;
  padding: 0;
  padding-bottom: 48px;

  &-actions {
    padding: 0 24px;
  }

  &-header {
    display: flex;
  }

  &-status {
    padding: 20px 24px 0 0;
  }

  &-vacancy {
    padding-left: 24px;
  }

  &-wrapper {
    width: 100%;
    padding: 12px 24px 0;
  }
}
