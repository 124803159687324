@import "../../styles/mixins.scss";

.ui-form--input-mask {
  .ant-row {
    display: contents;
  }
  .ant-form-item-label {
    display: flex;
    label {
      @include label;
    }
    label.ant-form-item-required {
      flex-direction: row-reverse;
    }
  }
  input::placeholder {
    color: $placeholder-color !important;
    font-weight: 500 !important;
  }
}
