.field-phone {
  &-overlay {
    width: 280px;
    z-index: 999;
  }
  &-contact {
    &-phone {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    &-button {
      height: 32px;
      width: 112px !important;
      margin-left: 24px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
    &-title {
      color: #a6acb9;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }

    &-number {
      font-size: 14px;
      line-height: 32px;
      font-weight: 500;
      color: #0057ac;
      cursor: pointer;
    }
  }
}
