@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-button {
  @include flex-block;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: 0.3s;

  &--default {
    background-color: $blue-color-9;
    color: #fff;

    &:hover {
      background-color: $blue-color-8;
    }

    &:active {
      background-color: $blue-color-8;
    }
  }

  &--primary {
    background-color: $blue-color-3;
    color: $blue-color-9;

    &:hover {
      background-color: $blue-color-6;
    }

    &:active {
      background-color: $blue-color-6;
    }
  }

  &--green {
    background-color: rgba(30, 178, 45, 0.2);
    color: #1eb22d;
  }

  &--link {
    display: inline-flex;
    background: none;
    color: $blue-color-9;
  }

  &--normal {
    padding: 10px 24px;
    height: 40px;

    &.ui-button--empty {
      padding: 10px;
    }
  }

  &--small {
    padding: 4px;
    height: 24px;
  }

  &--disabled {
    @include disabled;
    cursor: not-allowed;

    &:hover {
      background-color: $bg-disabled-color;
      box-shadow: none;
    }
  }

  &--loading {
    pointer-events: none;
  }

  &--block {
    width: 100%;
  }

  &-icon {
    display: inline-block;
    &--with-text {
      margin-right: 8px;
    }

    &--spinner {
      @include animation;
    }

    &-right {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}
