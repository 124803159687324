// Color Blue
$blue-10: #00468a;
$blue-9: #0057ac;
$blue-8: #3379bd;
$blue-7: #9bc3ff;
$blue-6: #bfd9ff;
$blue-3: #dfebfd;
$blue-1: #eff5fe;

// Color Neutral
$neutral-10: #000000;
$neutral-9: #181818;
$neutral-8: #1f1f1f;
$neutral-7: #a6acb9;
$neutral-6: #e5e7ea;
$neutral-5: #f1f2f4;
$neutral-3: #efefef;
$neutral-2: #fafafb;
$neutral-1: #ffffff;

// Color Red
$red-10: #d4000b;
$red-9: #f5222d;
$red-8: #ff5962;
$red-3: #ffc9d2;
$red-2: #ffdee4;
$red-1: #ffeaee;

// Color Green
$green-10: #66a84e;
$green-2: #d2f0d5;

// Color Orange
$orange-10: #faaa16;
$orange-8: #fec500;
$orange-1: #fff4ce;

// Color Cyan
$cyan-10: #139bd6;
$cyan-9: #43bfe8;
$cyan-5: #b7edff;

// Color Purple
$purple-10: #597eff;
