.table-field {
  display: flex;
  align-items: center;
  position: relative;

  &-value {
    font-size: 16px;
    line-height: 30px;
  }
  &-input {
    height: 30px;
  }

  &-icon {
    display: inline-block;
    margin-left: auto;
    cursor: pointer;

    &--edit {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &:hover {
    color: #0057ac;
    .field-count-icon--edit {
      opacity: 1;
    }
  }
}
