.profile-security {
  &-wrapper {
    padding: 30px 160px;
  }

  &-form {
    max-width: 300px;
    margin: 0 auto;
  }

  &-button-box {
    display: flex;
    justify-content: space-between;
  }
}
