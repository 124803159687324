@import "../styles/palette.scss";

.ui-tabs {
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $blue-color-9 !important;
  }

  .ant-tabs-tab:hover {
    color: $blue-color-9 !important;
  }

  .ant-tabs-ink-bar {
    background: $blue-color-9 !important;
  }
}
