.viewmode {
  display: flex;
  align-items: center;

  &-toggle {
    margin: 0 4px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: #DFEBFD;
      border-radius: 6px;
    }
  }
}