@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

.notification {
  border-radius: 8px !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;

  &-title {
    font-weight: 500;
    color: $black-color;
    padding: 0;
    margin: 0;
    margin-left: 4px;
  }

  &--normal {
    min-height: 40px !important;
    padding: 4px 8px !important;
    .notification-title {
      line-height: 20px;
      font-size: 14px;
    }
  }

  &--small {
    min-height: 24px !important;
    padding: 4px !important;
    .notification-title {
      line-height: 16px;
      font-size: 12px;
    }
  }

  &--error {
    background: #efd4d4;
    &.Toastify__toast--error {
      background: #efd4d4;
    }
  }

  &--success {
    background: #d4efdf;
    &.Toastify__toast--success {
      background: #d4efdf;
    }
  }

  &--warning {
    background: #fefbea;
    &.Toastify__toast--warning {
      background: #fefbea;
    }
  }

  &--info {
    background: #dfebfd;
    &.Toastify__toast--info {
      background: #dfebfd;
    }
  }

  &-container {
    display: flex;
    align-items: center;
  }

  &-body {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
}

.spinner {
  @include position-absolute-center;
}
