.reports {
  height: 100%;
  background-color: rgba(166, 172, 185, 0.1);
  padding: 16px 20px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 36px;
  }

  &-cards {
    display: flex;

    &--list {
      align-items: center;
      flex-direction: column;
    }
    &--grid {
      flex-direction: row;
    }
  }

  &-card {
    margin-bottom: 24px;

    &--grid {
      margin-right: 24px;
    }
  }
}
