@import "../styles/palette.scss";

.filters-count {
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;

  &-content {
    display: flex;
    align-items: center;
  }

  &-icon,
  &-text {
    margin-right: 8px;
    &:hover {
      color: $blue-color;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    background-color: $grey-color-5;
    border-radius: 6px;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    span:first-child {
      margin-right: 4px;
    }
  }
}
