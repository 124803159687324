.reply-details {
  height: 197px;
  overflow-y: scroll;
  .reply-detail {
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #efefef;

    &-label {
      color: #a6acb9;
    }

    &-value {
      margin-left: 10px;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  &:first-child {
    padding: 0;
  }
}
