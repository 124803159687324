.avito-form {
  &-desc {
    margin-bottom: 24px;
  }

  &-notification {
    margin: 16px 0;
  }

  &-salary-block {
    margin-bottom: 16px !important;
  }

  &-error {
    color: #e62249;
  }

  &-checkbox label {
    width: 270px !important;
  }
}
