.historyList {
  &-item {
    margin-bottom: 36px;
    position: relative;

    &:not(:last-child)::after {
      position: absolute;
      bottom: -18px;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #EFEFEF;
    }
  }

  &-showMore {
    margin-left: 250px;
  }
}
