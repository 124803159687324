.form-candidate {
  display: flex;
  flex-direction: column;

  &-scrollable {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-phone {
    font-weight: 600 !important;
  }
  &-save-bar {
    position: sticky;
    padding: 16px;
    border-top: 1px solid rgba(166, 172, 185, 0.3);
    background: #fff;
    width: 100%;
    bottom: -13px;
    left: 0;
    z-index: 1050;
    padding-bottom: 24px;
  }

  &-details {
    border-bottom: 1px solid rgba(166, 172, 185, 0.3);
    margin-bottom: 30px;
    padding: 0 20px;
  }
  &-btn {
    position: absolute;
    top: -60px;
    right: 0;
    z-index: 1000;
  }
}
