@import "components/UI/styles/palette.scss";
@import "components/UI/styles/mixins.scss";

.profile-card {
  padding: 16px;
  width: 350px;
  margin: 0 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-role {
    text-align: center;
    color: $grey-color-7;
  }

  &-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
  }

  &-row-info {
    margin-bottom: 16px;
    @include nowrap;
  }

  &-field-info {
    width: 100px;
    display: inline-block;
    color: $grey-color-7;
  }

  &-user-info {
    font-weight: bold;
    margin-left: 16px;
    margin-bottom: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
