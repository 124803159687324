.candidate-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &--empty {
    padding-bottom: 16px !important;
  }

  &-btn {
    max-width: 40px;
    flex-shrink: 0;
  }

  &-input {
    flex-grow: 1;
    max-width: 300px;
    &-icon {
      cursor: pointer;
    }
  }

  &--wide {
    flex-grow: inherit;
    width: 32%;
  }
}
