@import "../styles/palette.scss";
@import "../styles/mixins.scss";

@mixin select-arrow {
  color: $blue-color-9;
  @include square(16px);
  transition: transform 0.3s;
  :hover {
    color: #2688ff;
  }
}

.ui-select {
  &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
    border-radius: 8px;
    color: $black-color;
    font-size: 14px;
    @include border($border-color);
  }
  &.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):hover
    > .ant-select-selector {
    @include hover;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: $blue-color-9;
    box-shadow: none;
    color: $blue-color-9;
  }
  &-small {
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
      border-color: $white-color;
    }
    &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
      border: none;
    }
  }
  &.ant-select-multiple .ant-select-selection-item {
    border-radius: 8px;
    color: $black-color;
  }
  .ant-select-selection-item-remove {
    color: #999;
    padding-right: 3px;
  }
  .ant-select-arrow {
    @include select-arrow;
  }
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: $disabled-color;
  }
  &.ant-select-selection-search-input {
    font-size: 14px;
    height: 40px;
  }
  &.ant-select-item {
    border-radius: 4px;
  }

  &-dropdown.ant-select-dropdown {
    border-radius: 8px;
    padding: 4px;
  }
  .ant-select-arrow {
    @include select-arrow;
  }

  &.ant-select-open .ant-select-arrow {
    transform: rotate(180deg);
  }
}
.ant-select-item {
  border-radius: 4px;
}
.ui-select.ant-select-single.ant-select-show-search {
  .ant-select-arrow {
    transform: none;
  }
}

::placeholder {
  color: $placeholder-color !important;
}

.ant-select-selection-placeholder {
  color: $placeholder-color !important;
}
