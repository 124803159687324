.ui-modal {
  &--small {
    width: 474px !important;
  }

  &--normal {
    width: 574px !important;
  }

  &--large {
    width: 674px !important;
  }

  .ant-modal-header {
    border-radius: 12px 12px 0 0 !important;
    border-bottom: none !important;
  }

  .ant-modal-close-x {
    width: auto !important;
    height: auto !important;
    margin: 16px 16px 0 0 !important;
  }

  .ant-modal-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .ant-modal-content {
    border-radius: 12px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: 52px;
      height: 100%;
      background-color: rgba(0, 87, 172, 0.14);
    }
  }
  &.no-side-panel {
    .ant-modal-content:after {
      display: none;
    }

    .ant-modal-body {
      padding: 12px 26px 26px 26px !important;
    }
  }

  .ant-modal-body {
    padding: 12px 78px 26px 26px !important;
    color: #000;
    opacity: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .ant-modal-footer {
    border-top: none !important;
    padding: 0 26px 26px !important;
    text-align: left !important;
  }

  &-wrapper {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
