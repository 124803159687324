.info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #efefef;
  color: #a6acb9;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
