.candidates-actions-modal {
  padding: 20px;

  &-text {
    color: #a6acb9;
    margin-bottom: 20px;
  }

  &-btn {
    margin-top: 12px;
    width: 60%;
  }
}
