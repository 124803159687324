.search-download {
  display: flex;
  align-items: center;
  gap: 0 16px;
  padding: 0 20px;

  &-input {
    width: 435px;
  }
}
