.callTime-container {
  margin-bottom: 30px;
}
.error-message {
  font-size: 10px;
  color: #e62249;
}
.callTime-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  &--input {
    width: 80px;
    text-align: center;
  }

  &--dash {
    font-size: 14px;
  }
}
