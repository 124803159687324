.profile-hh {
  padding: 30px 160px;

  .profile-hh-entrance {
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .block-item {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .block-center {
      display: flex;
      justify-content: center;
      align-items: center;

      .hh-logo {
        height: 32px;
        margin-right: 8px;
      }

      .hh-text {
        font-size: 18px;
      }

      .close-icon {
        margin-left: 8px;
        font-size: 18px;
        margin-top: 2px;
        cursor: pointer;
      }
    }
    &-user-info {
      margin-top: 20px;
      &-item {
        gap: 4px;
        display: flex;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        &-label {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
  }
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
