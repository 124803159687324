.ui-cascader {
  &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
    border-radius: 8px;
    border: solid 1px #a6acb94d;
    color: #000000;
    font-size: 14px;
  }
  & .ant-cascader-menu-item:hover {
    font-weight: 500;
    background-color: transparent;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #0057ac;
    box-shadow: none;
    color: #0057ac;
  }
  & .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
    font-weight: 500;
    background-color: transparent;
  }

  &-small {
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
      border-color: white;
    }
    &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
      border: none;
    }
  }

  &.ant-select-multiple .ant-select-selection-item {
    border-radius: 8px;
    color: #000000;
  }
  .ant-select-selection-item-remove {
    color: #999;
    padding-right: 3px;
  }
  .ant-select-arrow {
    color: #0057ac;
    width: 16px;
    height: 16px;
    :hover {
      color: #2688ff;
    }
  }
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #778196;
  }
  &.ant-select-selection-search-input {
    font-size: 14px;
    height: 40px;
  }
  &.ant-select-item {
    border-radius: 4px;
  }

  & .ant-select-dropdown {
    border-radius: 8px;
  }
  .ant-select-arrow {
    color: #0057ac;
    width: 16px;
    height: 16px;
    transition: transform 0.3s;
    &:hover {
      color: #2688ff;
    }
  }

  &.ant-select-open .ant-select-arrow {
    transform: rotate(180deg);
  }
}
