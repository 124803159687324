@import "components/UI/styles/mixins.scss";
@import "components/UI/styles/palette.scss";
.action-panel-container {
  position: relative;
  padding-top: 24px;

  &-btn {
    margin-bottom: 10px;
    width: 100%;
  }
  &-candidate-status {
    @include label;
    font-size: 14px;
    &-color {
      color: $blue-color-8;
    }
  }
}
