@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-date-picker {
  border-radius: 8px !important;
  color: $black-color !important;
  height: 40px !important;
  @include border($border-color);

  &.ant-picker:hover {
    @include hover;
  }

  &.ant-picker:focus {
    @include focus;
  }

  &.ant-picker-small {
    height: 32px !important;
  }

  &--disabled {
    @include disabled;
  }
}
