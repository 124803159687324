.content-layout {
  height: 100% !important;

  &-left {
    padding: 24px 16px;
    border-right: 1px solid rgba(166, 172, 185, 0.3);
    position: relative;
    height: 100%;
  }

  &-center {
    position: relative;
    height: 100%;
  }

  &-right {
    padding: 24px 16px;
    border-left: 1px solid rgba(166, 172, 185, 0.3);
    position: relative;
    background-color: rgba(166, 172, 185, 0.1);
    height: 100%;
  }
}
