@import "../../styles/palette.scss";

.ui-form-label {
  height: 20px;

  &-name {
    color: $label-color;
    font-weight: 600;
    font-size: 14px;
  }
  &-required {
    color: $required-color;
    margin-left: 2px;
    &-text {
      font-size: 10px;
    }
  }
}
