.multi-vacancy-container {
  display: flex;
  flex-direction: column;
  &--table {
    overflow-y: auto;
    height: 60vh;
  }
  &-modal {
    padding: 20px;

    &-btn {
      margin-top: 12px;
      width: 60%;
    }
  }
}
