.report {
  height: 100%;
  padding: 16px 20px;
  position: relative;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
