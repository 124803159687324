.ui-form-radio {
  .ant-row {
    display: contents;
  }
  .ant-form-item-label {
    display: flex;
    label {
      color: #a6acb9 !important;
      height: 20px;
      margin-bottom: 4px;
      font-weight: 600 !important;
      &::after {
        content: none !important;
      }
    }
    label.ant-form-item-required {
      flex-direction: row-reverse;
    }
  }
}
