@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.ui-checkbox {
  .ant-checkbox-wrapper {
    &.ant-checkbox-wrapper-disabled {
      color: $grey-color-6;
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 4px;
      border: 1px solid $blue-color-9;
      background-color: $white-color;

      &::after {
        border: solid $blue-color-9;
        border-width: 0 2px 2px 0;
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $white-color;
        border-color: $blue-color-9;

        &::after {
          border-color: $blue-color-9;
        }
      }
    }

    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        @include disabled;
        cursor: not-allowed;
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          @include disabled;

          &::after {
            border-color: $grey-color-7;
          }
        }
      }
    }

    &:hover .ant-checkbox-inner {
      border-color: $blue-color-9;
    }
  }
}
