.field-position {
  display: flex;
  align-items: center;
  padding: 16px 0;

  &-hover {
    color: #0057ac;
  }

  &-select {
    margin-right: 4px !important;
    width: 100% !important;
  }

  &-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-arrow {
    user-select: auto !important;
    cursor: pointer;
    pointer-events: all;
  }

  &-icon {
    display: inline-block;
    margin-left: auto;
    cursor: pointer;
  }
}
