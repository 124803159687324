.candidates-filters {
  &-btn {
    margin-bottom: 8px;
  }

  &-icon {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
