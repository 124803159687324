.cold-vacancy {
  position: relative;
  display: flex;
  flex-direction: column;

  &-info {
    margin-bottom: 24px;
    padding: 0 20px;
  }
}
