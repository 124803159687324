@import "../../UI/styles/palette.scss";

.create-candidate-container {
  &__phone-check {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__existing-candidate-link {
    color: $blue-color-8;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $blue-color-7;
      text-decoration: underline;
    }
  }
}
