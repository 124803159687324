.layout__wrapper.is-expanded {
  margin-left: 230px;
}
.layout__wrapper.is-collapsed {
  margin-left: 64px;
}

.layout--system .layout__content {
  margin-top: 64px;
}

.layout__content {
  background-color: #fff !important;
}

.layout__logo {
  margin-top: 10px;
  height: 32px;
}

.Toastify__toast-container {
  z-index: 999999;
  width: 410px;
}
.ant-tooltip .ant-tooltip-inner {
  border-radius: 8px !important;
}
