.filters {
  display: flex;
  align-items: center;
}

.filtersModal {
  &-filters {
    margin-bottom: 24px;
  }

  &-btn {
    width: 100%;
    margin-top: 12px;
  }
}
