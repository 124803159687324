@import "../UI/styles/mixins";

.vacancyInfo-container {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-bottom: 1px solid rgba(166, 172, 185, 0.3);

  .ymaps-2-1-79-copyrights-pane {
    display: none !important;
  }

  &-status {
    display: inline-block;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid;
    border-radius: 8px;
  }

  &-wrapper {
    @include flex-block($justifyContent: space-between);
  }
  &-column {
    max-height: 400px;
    overflow-y: auto;
    border-right: 1px solid rgba(166, 172, 185, 0.3);
    height: 100%;
    padding: 24px 0;
  }

  &-item {
    margin-bottom: 4px;
    &--inline {
      display: flex;
      align-items: flex-start;
    }
  }

  &-title {
    margin-bottom: 12px;
  }

  &-address {
    margin-bottom: 20px;
  }

  &-salary {
    display: flex;
    align-items: center;
  }

  &-short {
    margin-top: 2px;
    margin-left: 4px;
    word-break: break-word;
    overflow: hidden;
    &-cc {
      color: #a6acb9;
    }
  }

  &-publish {
    padding: 0 8px;
    text-wrap: nowrap;
    font-size: 13px;
    margin-top: 24px;
    width: 190px;
  }

  &-checkbox {
    display: flex !important;
    flex-direction: column !important;
    color: #000 !important;

    & > label {
      margin-bottom: 8px;
    }
  }

  &-close {
    display: flex;
    align-items: center;

    &-publish {
      &:first-child {
        margin-right: 16px;
      }
      width: 184px;
    }
  }

  &-error {
    color: #e62249;
    font-size: 12px;
    margin-top: 4px;
  }
}
