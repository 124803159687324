@import "../../components/UI/styles/palette.scss";

.plan-table {
  border-radius: 8px 8px 0 0;
  border-spacing: 0;
  border-collapse: initial;
  font-size: 13px;
  font-weight: 500;

  &-td-active {
    background: $blue-color-3;
  }

  tr,
  td {
    padding: 12px;
  }

  tr:last-child td:first-child {
    border-left: 1px solid $grey-color-3;
    border-bottom-left-radius: 8px;
  }

  tr:last-child td:last-child {
    border-right: 1px solid $grey-color-3;
    border-bottom-right-radius: 8px;
  }

  tr:first-child td:first-child {
    border-left: 1px solid $grey-color-3;
    border-top-left-radius: 8px;
  }

  tr:first-child td:last-child {
    border-right: 1px solid $grey-color-3;
    border-top-right-radius: 8px;
  }

  tr:first-child td {
    border-top: 1px solid $grey-color-3;
  }

  tbody > tr td:not(:last-child) {
    border-right: 1px solid $grey-color-3;
  }

  tbody > tr td:first-child {
    border-left: 1px solid $grey-color-3;
  }

  tbody > tr td:last-child {
    border-right: 1px solid $grey-color-3;
  }

  tbody > tr td {
    border-bottom: 1px solid $grey-color-3;
  }

  tr td:first-child {
    color: $grey-color-7;
  }

  &-name {
    color: $blue-color-9;
    cursor: pointer;
    width: 103px;
  }
}
