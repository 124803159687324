@import "../../styles/mixins.scss";

.ui-form-tree-select {
  .ant-row {
    display: contents;
  }
  .ant-form-item-label {
    display: flex;
    label {
      @include label;
    }
    label.ant-form-item-required {
      flex-direction: row-reverse;
    }
  }
}
