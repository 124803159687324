.rabotut-form {
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-desc {
    margin-bottom: 24px;
  }
  &-checkbox {
    margin-bottom: 20px !important;
  }
  &-notification {
    margin: 16px 0;
  }

  &-info-tag {
    margin-bottom: 16px;
  }

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  &-salary-error {
    height: 20px;
  }
}
