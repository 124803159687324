@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

@import "./components/UI/styles/mixins.scss";

@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");

  font-weight: 400;
  font-style: normal;
}

body #root {
  min-height: 100vh;
  display: flex;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
}

.ant-tag {
  margin-bottom: 8px;
}

.ant-card-body {
  padding: 10px !important;
}

#headlessui-portal-root {
  position: relative;
  z-index: 100000;
}
*:focus-visible {
  outline: none;
}

// кастомный скролл
.global-scrollbar {
  @include global-scrollbar;
}

// три точки в конце текста, если не помещается в строке
.nowrap {
  @include nowrap;
}
