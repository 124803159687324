@import "components/UI/styles/mixins.scss";

.replies-container {
  position: relative;
  height: 100%;
  padding: 24px;

  &-spinner {
    @include position-absolute-center;
  }
}
