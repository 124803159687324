@import "../styles/palette.scss";

.ui-title {
  color: $black-color;
  font-style: normal;
  font-weight: 600;

  &--h1 {
    font-size: 48px;
    line-height: 64px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &--h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  &--h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  &--h4 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &--h5 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}
