.hh-form {
  &-desc {
    margin-bottom: 24px;
  }

  &-notification {
    margin: 16px 0;
  }

  &-error {
    color: #e62249;
  }
}
