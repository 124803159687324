.candidates-page {
  padding: 24px 16px;
  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      margin-bottom: 0;
    }
  }
}
