.ui-pagination {
  display: inline-flex;
  flex-direction: column;
  height: 28px;

  &-container {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #a6acb9;
    height: 28px;
  }

  &-icon {
    display: inline-block;
    padding: 0 8px;
    cursor: pointer;

    &_disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-name {
    display: inline-block;
    margin-right: 4px;
  }

  &-suf {
    margin: 0 4px;
  }

  &-total {
    font-size: 14px;
    line-height: 20px;
    color: #000;
  }

  &-input {
    height: 28px !important;
    border: none !important;
    background-color: #f1f2f4 !important;

    &:focus {
      box-shadow: none !important;
      border: 0 !important;
      outline: 0 !important;
    }
  }
}
