.field-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;

  &-cascader {
    width: 155px;
    &-dropdown .ant-cascader-menu {
      max-height: unset;
      height: auto;
      overflow: visible;
    }
    .ant-select-selection-placeholder {
      color: #43bfe8 !important;
    }
  }
}
