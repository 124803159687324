.candidates-container {
  position: relative;
  height: 100%;
  padding-top: 24px;

  &-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-table {
    overflow: hidden;
    min-height: 528px;

    &-copyTooltip {
      display: flex;
      align-items: center;
    }

    &-copy {
      cursor: pointer;
    }

    &-id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .candidate-row--highlighted {
      color: rgba(166, 172, 185, 1);
    }
    &-match-letter {
      color: #0057ac;
    }

    &-default-letter {
      color: #000000;
    }
  }

  &-pagination {
    margin-top: 12px;
    margin-bottom: 64px;
  }
}
