.comment {
  position: relative;
  padding-left: 16px;

  &-createdAt {
    color: #a6acb9;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &-title {
    margin-top: 16px;
    margin-bottom: 2px;
  }

  &-department {
    color: #0057ac;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 12px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 2px;
    height: 100%;
    background-color: #43bfe8;
  }
}
