.candidate-history {
  overflow-y: auto;
  max-height: 240px;
  margin-top: 20px;
  border-top: 1px solid #efefef;
  &-item {
    border-left: 2px solid #43bfe8;
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 12px;
    &-text {
      font-size: 14px;
    }
    &-date {
      color: #a6acb9;
    }
  }
}
